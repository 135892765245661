import * as Sentry from "@sentry/browser";

export function logEvent(type: string, params?: object) {
    // Create a new object to avoid mutating the original params
    const extendedParams = {
        ...params, // Spread the existing params
        type        // Add the 'type' field
    };

    console.log(type, extendedParams, JSON.stringify(extendedParams));

    fetch('/log_event/', {
        method: 'POST',
        body: JSON.stringify(extendedParams)
    }).then((resp) => {}).catch((e) => {Sentry.captureException(e)});
}
